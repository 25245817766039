import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setInitialVisit, setLanguage } from '../store/actions/config';

import { LandingLayout } from '../layouts';
import { api } from '../services';

const LandingPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const { fetchLandingPageData } = api();

  const changeLang = lang => dispatch(setLanguage(lang));
  const changeFlagVisit = () => dispatch(setInitialVisit());

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        const response = await fetchLandingPageData(currentLang);
        console.debug('currentLang', currentLang);
        if (!currentLang) {
          changeFlagVisit();
        }
        changeLang(response.languages[0].abbr);
        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang]);

  return <LandingLayout data={data} loading={loading} error={error} />;
};

export default LandingPage;
